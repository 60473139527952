#NewExport {
  padding: 50px;

  li:nth-child(odd) {
    background: #f4f6f899;
  }

  li {
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 10px;

    label.Polaris-Choice {
      margin-right: auto;
    }
  }
  li > div:first-child {
    display: flex;
  }

  li > .Polaris-ChoiceList__ChoiceChildren .Polaris-Card {
    background: transparent;
    box-shadow: none;

    // padding-left: 0;
    // padding-top: 0;
    margin-top: 10px;
    margin-right: 15px;
    border-top: 0.1rem solid #d3dbe2;
  }
  li
    > .Polaris-ChoiceList__ChoiceChildren
    .Polaris-Card
    .Polaris-Card__Section {
    padding: 0;
  }
  li
    > .Polaris-ChoiceList__ChoiceChildren
    .Polaris-Card
    .Polaris-Card__Section
    li {
    padding: 5px;
    padding-left: 10px;
    background: transparent;
  }
}

#SingleExportJob,
#SingleImportJob {
  padding: 50px;

  li:nth-child(odd) {
    background: #f4f6f899;
  }

  li {
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 10px;

    label.Polaris-Choice {
      margin-right: auto;
    }
  }
  li > div:first-child {
    display: flex;
  }

  li > .Polaris-ChoiceList__ChoiceChildren .Polaris-Card {
    background: transparent;
    box-shadow: none;

    // padding-left: 0;
    // padding-top: 0;
    margin-top: 10px;
    margin-right: 15px;
    border-top: 0.1rem solid #d3dbe2;
  }
  li
    > .Polaris-ChoiceList__ChoiceChildren
    .Polaris-Card
    .Polaris-Card__Section {
    padding: 0;
  }
  li
    > .Polaris-ChoiceList__ChoiceChildren
    .Polaris-Card
    .Polaris-Card__Section
    li {
    padding: 5px;
    padding-left: 10px;
    background: transparent;
  }
}

#SingleImportJob {
  padding-left: 15px;
  padding-right: 15px;
}
